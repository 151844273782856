import axios from 'axios';
import BasicService from './basic.service'

class MainService extends BasicService {

    getProducts() {
        return this.checkError(axios.get(this.BASE_URL + 'products'));
    }

    getNews() {
        return this.checkError(axios.get(this.BASE_URL + 'news'));
    }

    getPartners() {
        return this.checkError(axios.get(this.BASE_URL + 'partners'))
    }

    getCounters() {
        return this.checkError(axios.get(this.BASE_URL + 'counters'))
    }
}

export default new MainService();