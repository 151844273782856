export default class BasicService {
    constructor() {
        this.BASE_URL = 'https://panel.ro-bot.net/api/';
    }

    checkError(result) {
        return new Promise((resolve, reject) => {
            result.then(
                response => {
                    resolve(response);
                },
                error => {
                    let err = "Undefined error";

                    if (error.response && error.response.data && error.response.data.message)
                        err = error.response.data.message;
                    else if (error.message)
                        err = error.message;
                    else if (error.toString())
                        err = error.toString();
                    
                    console.log(err);

                    reject(error);
                },
            )
        });
    }
}