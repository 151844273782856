<template>
    <main class="home-main">
        <section class="entry">
            <Particles
                id="tsparticles"
                :options="{
            background: {
                color: {
                    value: 'transparent'
                },
            },
            fpsLimit: 60,
            interactivity: {
                detectsOn: 'canvas',
                events: {
                    onClick: {
                        enable: true,
                        mode: 'push'
                    },
                    onHover: {
                        enable: true,
                        mode: 'attract'
                    },
                    resize: true
                },
                modes: {
                    bubble: {
                        distance: 200,
                        duration: 1,
                        opacity: 0.8,
                        size: 40
                    },
                    push: {
                        quantity: 1
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4
                    }
                }
            },
            particles: {
                color: {
                    value: '#ff9000'
                },
                lineLinked: {
                  enable: true,
                  distance: 150,
                  color: '#ff9000',
                  opacity: 0.4,
                  width: 1
                },
                collisions: {
                    enable: true
                },
                move: {
                    direction: 'none',
                    enable: true,
                    outMode: 'out',
                    random: false,
                    speed: 3,
                    straight: false
                },
                number: {
                    density: {
                        enable: true,
                        value_area: 800
                    },
                    value: 40
                },
                opacity: {
                    value: 0.5,
                    random: true
                },
                shape: {
                    type: 'circle'
                },
                size: {
                    random: true,
                    value: 10
                }
            },
            detectRetina: true
        }"
            />

            <div class="inside container">
                <div class="row">
                    <transition
                            name="from-left"
                    >
                        <div v-if="show" class="order-lg-0 col-lg-6 col-md-12 order-sm-1 order-1 column text-wrapper">
                            <h1>{{ $t('home.name') }}</h1>
                            <h4>{{ $t('home.slogan') }}</h4>
                            <a href="#" v-scroll-to="{
                                 el: '#about-us',
                                 offset: -240,
                                    }" class="btn btn-lg primary-button">
                                <i class="fa fa-arrow-down"/> {{ $t('home.checkYourself') }}
                            </a>
                        </div>
                    </transition>
                    <transition
                            name="from-right"
                    >
                        <div class="order-lg-1 col-lg-6 cold-md-12 order-sm-0 order-0 column" v-if="show">
                            <img :src="$store.getters.img('/assets/images/logo-home.png')"/>
                        </div>
                    </transition>
                </div>
            </div>
        </section>

        <section class="home-section information">
            <img :src="$store.getters.img('/assets/svg/wave-home.svg')" class="wave"/>

            <div class="container">
                <div class="row" id="about-us">
                    <div class="col-xl-12 about-server">
                        <h3 class="section-title">{{ $t('home.aboutUs.title') }}</h3>
                        <p>{{ $t('home.aboutUs.desc') }}</p>
                    </div>
                </div>

                <div class="row counters">
                    <div class="col-xl-4">
                        <div class="card">
                            <div class="card-body d-flex justify-content-center">
                                <b-skeleton v-if="loadingCounters" width="100px"/>
                                <counter v-else :to="counters.licenses"/>
                            </div>
                            <div class="card-footer">
                                <i class="fa fa-users mr-1"/>
                                {{ $t('home.counters.soldLicenses') }}
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4">
                        <div class="card">
                            <div class="card-body d-flex justify-content-center">
                                <b-skeleton v-if="loadingCounters" width="100px"/>
                                <counter v-else :to="counters.products"/>
                            </div>
                            <div class="card-footer">
                                <i class="fa fa-robot mr-1"/>
                                {{ $t('home.counters.apps') }}
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4">
                        <div class="card">
                            <div class="card-body d-flex justify-content-center">
                                <b-skeleton v-if="loadingCounters" width="100px"/>
                                <counter v-else :to="counters.partners"/>
                            </div>
                            <div class="card-footer">
                                <i class="fa fa-handshake mr-1"/>
                                {{ $t('home.counters.partners') }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-12 why-us">
                        <h3 class="section-title">{{ $t('home.whyUs.title') }}</h3>
                        <vue-typed-js :backDelay="2000" :loop="true" :loopCount="99999"
                                      :strings="$t('home.whyUs.strings')" :typeSpeed="80"
                                      class="justify-content-center">
                            <h3 class="section-title primary-title"><span class="typing"></span></h3>
                        </vue-typed-js>
                    </div>
                </div>

                <div class="row pros justify-content-center">
                    <div class="col-xl-3 col-sm-6">
                        <div class="flip-card">
                            <div class="flip-card-inner">
                                <div class="flip-card-front">
                                    <div class="icon">
                                        <i class="fa fa-users"/>
                                    </div>
                                    <span>{{ $t('home.whyUs.cards.team.title') }}</span>
                                </div>
                                <div class="flip-card-back">
                                    {{ $t('home.whyUs.cards.team.desc') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6">
                        <div class="flip-card">
                            <div class="flip-card-inner">
                                <div class="flip-card-front">
                                    <div class="icon">
                                        <i class="fa fa-handshake"/>
                                    </div>
                                    <span>{{ $t('home.whyUs.cards.support.title') }}</span>
                                </div>
                                <div class="flip-card-back">
                                    {{ $t('home.whyUs.cards.support.desc') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6">
                        <div class="flip-card">
                            <div class="flip-card-inner">
                                <div class="flip-card-front">
                                    <div class="icon">
                                        <i class="fa fa-robot"/>
                                    </div>
                                    <span>{{ $t('home.whyUs.cards.configurator.title') }}</span>
                                </div>
                                <div class="flip-card-back">
                                    {{ $t('home.whyUs.cards.configurator.desc') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6">
                        <div class="flip-card">
                            <div class="flip-card-inner">
                                <div class="flip-card-front">
                                    <div class="icon">
                                        <i class="fa fa-tachometer-alt"/>
                                    </div>
                                    <span>{{ $t('home.whyUs.cards.superduper.title') }}</span>
                                </div>
                                <div class="flip-card-back">
                                    {{ $t('home.whyUs.cards.superduper.desc') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-12 products-header" id="products">
                        <h3 class="section-title">{{ $t('home.products.title') }}</h3>
                    </div>
                </div>

                <div v-if="loadingProducts" class="row justify-content-center products">
                    <div class="col-xl-4 col-sm-6" v-for="i in [0,1,2]" :key="i">
                        <div class="card">
                            <div class="card-header">
                                <div class="logo">
                                    <b-skeleton width="150px" class="my-1"/>
                                </div>
                            </div>
                            <div class="card-body">
                                <h2><b-skeleton width="100px" class="m-auto"/></h2>
                                <div class="my-4">
                                    <b-skeleton width="300px" class="my-1"/>
                                    <b-skeleton width="300px" class="my-1"/>
                                    <b-skeleton width="70px" class="my-1"/>
                                </div>
                                <hr>
                                <b-skeleton width="150px" class="m-auto"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row justify-content-center products">
                    <div class="col-xl-4 col-sm-6" v-for="product in products" :key="product.name">
                        <div class="card">
                            <div class="card-header">
                                <div class="logo">
                                    <img :src="'https://panel.ro-bot.net/' + product.img"/>
                                </div>
                            </div>
                            <div class="card-body">
                                <h2>{{ product.name }}</h2>
                                <h5>{{ $t('products.' + product.name + '.header') }}</h5>
                                <div v-html="$t('products.' + product.name + '.desc')"></div>
                                <a :href="$t('products.' + product.name + '.url')" class="btn primary-button">
                                    <i class="fa fa-eye"/> {{ $t('home.checkYourself') }}
                                </a>
                                <hr>
                                <a href="https://panel.ro-bot.pl" target="_blank" class="btn primary-button">
                                    <i class="fa fa-money-bill"/> {{ product.cost }} PLN
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="home-section parallax">
            <div :style="'background-image: url(' + $store.getters.img('/assets/images/parallax.png') + ');'"
                 class="parallax-img"></div>
            <div class="inner">
                <h2 class="section-title">{{ $t('home.uWantUs.title') }}</h2>
                <a href="http://panel.ro-bot.net" target="_blank" class="btn primary-button"><i class="fa fa-shopping-cart"/> {{ $t('home.uWantUs.btn') }}</a>
            </div>
        </section>

        <section class="home-section news-section">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12 news" id="news">
                        <h3 class="section-title">{{ $t('home.news') }}</h3>
                    </div>
                </div>

                <div v-if="loadingNews" class="row news-wrapper">
                    <div class="col-xl-6 col-sm-12">
                        <div class="card">
                            <div class="wave-news">
                                <img :src="$store.getters.img('/assets/svg/wave-news.svg')"/>
                            </div>
                            <div class="card-header">
                                <b-skeleton width="200px"/>
                            </div>
                            <div class="card-body">
                                <b-skeleton width="250px" class="my-1"/>
                                <b-skeleton width="150px" class="my-1"/>
                            </div>
                            <div class="card-footer">
                                <span><b-skeleton width="50px"/></span>
                                <span><b-skeleton width="100px"/></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-sm-12">
                        <div class="card">
                            <div class="wave-news">
                                <img :src="$store.getters.img('/assets/svg/wave-news.svg')"/>
                            </div>
                            <div class="card-header">
                                <b-skeleton width="200px"/>
                            </div>
                            <div class="card-body">
                                <b-skeleton width="250px" class="my-1"/>
                                <b-skeleton width="150px" class="my-1"/>
                            </div>
                            <div class="card-footer">
                                <span><b-skeleton width="50px"/></span>
                                <span><b-skeleton width="100px"/></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="news.length" class="row news-wrapper">
                    <div v-for="singleNews in news" :key="singleNews.id" class="col-xl-6 col-sm-12">
                        <div class="card">
                            <div class="wave-news">
                                <img :src="$store.getters.img('/assets/svg/wave-news.svg')"/>
                            </div>
                            <div class="card-header">
                                {{ singleNews.title }}
                            </div>
                            <div class="card-body" v-html="singleNews.content">
                            </div>
                            <div class="card-footer">
                                <span class="news-author">{{ singleNews.user.fullName }}</span>
                                <span>{{ singleNews.createdAt | moment("DD-MM-YYYY HH:mm") }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row news-wrapper">
                    <div class="col-md-12 text-center">
                        <h4><i class="fa fa-info-circle"></i> {{ $t('home.noNews') }}</h4>
                    </div>
                </div>

            </div>
        </section>

        <section class="home-section partners">
            <img :src="$store.getters.img('/assets/svg/wave-home-partners.svg')" class="wave"/>

            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <h3 class="section-title">{{ $t('home.partners') }}</h3>
                    </div>
                </div>
                <div class="row">
                    <div v-if="loadingPartners" class="col-md-12 d-flex justify-content-center align-items-center">
                        <div class="spinner mt-5">
                            <b-spinner label="Loading..."></b-spinner>
                        </div>
                    </div>
                    <div v-else-if="partners.length" class="offset-xl-3 col-xl-6 d-flex justify-content-center">
                        <carousel
                            :autoplay="true"
                            :perPage="1"
                            :loop="true"
                            :navigationEnabled="true"
                            :value="partnerNum"
                            paginationActiveColor="#ff9000"
                        >
                            <slide
                                v-for="partner in partners"
                                :key="partner.id"
                                :img-src="'https://panel.ro-bot.net/' + partner.img"
                                v-on:slide-click="slideClicked"
                                :data-url="partner.url"
                                style="cursor: pointer; display: flex; align-items: center; justify-content: center;"
                            >
                                    <img :src="'https://panel.ro-bot.net/' + partner.img" style="width: 300px;"/>
                            </slide>
                        </carousel>
                    </div>
                    <div v-else class="col-md-12 text-center">
                        <h4><i class="fa fa-info-circle"></i> {{ $t('home.noPartners') }}</h4>
                    </div>
                </div>
            </div>
        </section>

    </main>
</template>

<script>
import MainService from "../services/main.service";
import { Carousel, Slide } from 'vue-carousel';

export default {
    name: "Home",
    components: {Carousel, Slide},
    data() {
        return {
            show: false,
            loadingNews: true,
            loadingPartners: true,
            loadingCounters: true,
            loadingProducts: true,
            news: [],
            partners: [],
            counters: {},
            partnerNum: 0,
            products: []
        }
    },
    computed: {
        prefix() {
            return '';
        }
    },
    mounted() {
        this.show = true;
        this.refresh();
    },
    methods: {
        refresh() {
            this.loadingNews = true;
            this.loadingPartners = true;
            this.loadingCounters = true;
            this.loadingProducts = true;

            MainService.getNews().then(
                response => {
                    this.news = response.data;
                    this.loadingNews = false;
                },
                error => {
                    this.loadingNews = false;
                    console.log(error)
                }
            );

            MainService.getPartners().then(
                response => {
                    this.partners = response.data;
                    this.loadingPartners = false;

                    for(let i=0; i<this.partners.length; i++)
                        setTimeout(() => this.partnerNum++, 10);

                    setTimeout(() => this.partnerNum = 0, 20);
                },
                error => {
                    this.loadingPartners = false;
                    console.log(error)
                }
            );

            MainService.getProducts().then(
                response => {
                    this.products = response.data;
                    this.loadingProducts = false;
                },
                error => {
                    this.loadingProducts = false;
                    console.log(error)
                }
            );

            MainService.getCounters().then(
                response => {
                    this.counters = response.data;
                    this.loadingCounters = false;
                },
                error => {
                    this.loadingCounters = false;
                    console.log(error)
                }
            );
        },
        slideClicked(partner) {
            window.open(partner.url, '_blank');
        }
    }
}
</script>

<style scoped>
    .from-right-enter-active, .from-right-leave-active {
        transition: opacity, transform 1s;
        transition-delay: .5s;
    }
    .from-right-enter, .from-right-leave-to {
        opacity: 0;
        transform: translateX(50%);
    }

    .from-left-enter-active, .from-left-leave-active {
        transition: opacity, transform 1s;
        transition-delay: .5s;
    }
    .from-left-enter, .from-left-leave-to {
        opacity: 0;
        transform: translateX(-50%);
    }
</style>